
import React, {Fragment} from 'react';

import Lottie from 'lottie-react';
import animationData from '../../data/Seccion_6/data.json';
import ConfirmarAsistenciaBoton from '../../components/ConfirmarAsistenciaBoton/ConfirmarAsistenciaBoton';
import "./Seccion_6.css"

const Seccion_6 = (props) => {

    const styleImage = {
        top: 0,
        padding: 0,
        border: 0,
        height: "100vh",
        position: "absolute",
        margin: "auto",
        transform: "translate(-50%, 400%)",
        zIndex: "-100"
      };

    const style = {
        top: 0,
        margin: '-1px 0px 0px 0px',
        padding: '0px 0px 0px 0px',
        border: '0px 0px 0px 0px',
        height: "100vh",
        width: '100%',
        transform: "translate(0%, 0%)",
        zIndex: "0"
      };  

    return (
        <Fragment>
            <Lottie 
                loop
                autoplay
                animationData={animationData}
                style={style}
            />
            {/* <img id="fonddoSeccion" src="./imgFondos/5.jpg" style={styleImage}></img>*/}

            {/* <object id="textoSeccion5" type="image/svg+xml" data="./img/Asistencia.svg"></object>   */}

            <div id="textoSeccion6">
                  <img src="./img/Asistencia.svg" alt=""/>
                  <div id="ConfirmarAsistenciaBoton" >
                    <ConfirmarAsistenciaBoton/>
                  </div>
            </div>              

        </Fragment>

    );
}
 
export default Seccion_6