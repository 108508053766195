import React, {useState, Fragment} from 'react'
import copy from "copy-to-clipboard";  

import "./LoginPersonalizadoCreador.css"


const  LoginPersonalizadoCreador = (props) => {

    const initialFormState = {  
        campo1: "",
        campo2: "",
        campo3: "",
      }

    const [ invitado, setInvitado ]   = useState(initialFormState)

    const handleInputChange = event => {
        console.log(event)
        const { name, value } = event.target
        setInvitado({ ...invitado, [name]: value })
        console.log(invitado);
    }
    
    let urlDato = "https://monicayjuanjo.nos-casamos.es/#/LP?t1=" + invitado.campo1 + "&t2=" + invitado.campo2 + "&t3=" + invitado.campo3;
    let encodedURL = encodeURI(urlDato);
    //console.log(encodedURL)

    // URL
    const urlCopiar = () => {
        let encodedURL = encodeURI(urlDato);
        console.log(encodedURL)
        copy(encodedURL);
        alert(encodedURL);

    }

    return ( 
        <Fragment>
            <div>
                <div id="datosLogin">
                    <label htmlFor="campo1">Ingresar texto Renglon 1</label>
                    <span>: &nbsp; </span>
                    <input className="form-control"  type="text"
                        name="campo1" id="campo1"
                        onChange={handleInputChange} value={invitado.campo1}
                    />
                    <br />
                    <label htmlFor="campo2">Ingresar texto Renglon 2</label>
                    <span>: &nbsp; </span>
                    <input className="form-control"  type="text"
                        name="campo2" id="campo2"
                        onChange={handleInputChange} value={invitado.campo2}
                    />
                    <br />
                    <label htmlFor="campo3">Ingresar texto Renglon 3</label>
                    <span>: &nbsp; </span>
                    <input className="form-control"  type="text"
                        name="campo3" id="campo3"
                        onChange={handleInputChange} value={invitado.campo3}
                    />
                </div>
                <br />
                <div id="urlLogin" onClick={urlCopiar}>
                    { encodedURL }
                </div>      
                <br />
                <dir>
                    <button className="btn btn-secondary" onClick={urlCopiar}>Copiar URL</button>
                </dir>
            </div>
        </Fragment>                  
    );



}
 
export default LoginPersonalizadoCreador;