import React, {Fragment} from "react";
import {Routes, Switch, Route} from "react-router-dom"
// Estilo
import "./App.css"

import ListadoInvitados from './HorizontalScrollEcard/ListadoInvitados/ListadoInvitados'
import HorizontalScrollEcard from './HorizontalScrollEcard/HorizontalScrollEcard'
import Login from './Login/Login'
import LoginPersonalizado from './LoginPersonalizado/LoginPersonalizado'
import LoginPersonalizadoCreador from './LoginPersonalizadoCreador/LoginPersonalizadoCreador';

function App() {
  
  return (
    <Fragment>
      <Routes>
          <Route path="/" element={<Login/>}></Route>
          <Route path="LP" element={<LoginPersonalizado/>}></Route>
          <Route path="LPCreator" element={<LoginPersonalizadoCreador/>}></Route>
          <Route path="LI9a5db2nt76kgd3t6e7" element={<ListadoInvitados/>} exact></Route>
          <Route path="t" element={<HorizontalScrollEcard/>}></Route>
          <Route path='*'
              element={
                <main>
                  <p>There's nothing here!</p>
                </main>
              }>
          </Route>
      </Routes>
      </Fragment>  
  );
}

export default App;


