import {useRef, Fragment} from 'react';

import Lottie from 'lottie-react';
import animationData from '../../data/Seccion_10/data.json';
import animationBalija from '../../data/Balija/data.json';


import "./Seccion_10.css"

const Seccion_10 = (props) => {

    const lottieRefBalija = useRef();

    const styleImage = {
        top: "-12px",
        padding: 0,
        border: 0,
        height: "100vh",
        position: "absolute",
        margin: "auto",
        transform: "translate(-50%, 600%)",
        zIndex: "-100"
      };

    const style = {
        top: 0,
        margin: '-2px 0px 0px 0px',
        padding: '0px 0px 0px 0px',
        border: '0px 0px 0px 0px',
        height: "100vh",
        width: '100%',
        transform: "translate(0%, 0%)",
      };  

    const styleBalija = {
        top: 0,
        padding: 0,
        border: 0,
        height: "100vh",
        position: "absolute",
        margin: "auto",
        left: "50%",
        width: '100%',
        transform: "translate(-50%, 698%)",
        zIndex: "300"
    };  

    const interactivityBalija = {
        mode: "scroll",
        actions: [
            {
                visibility: [0, 0.75],
                type: "stop",
                frames: [1],
            },
            {
                visibility: [0.75, 0.85],
                type: "play",
                frames: [2, 65],
            }
        ],
    };

    return (
        <Fragment>
            <Lottie 
                loop = "false"
                lottieRef={lottieRefBalija}
                animationData={animationBalija}
                interactivity={interactivityBalija}
                style={styleBalija}
            />
            <Lottie 
                loop
                autoplay
                animationData={animationData}
                style={style}
            />
            {/* <img id="fonddoSeccion7" src="./imgFondos/8.jpg" style={styleImage}></img> */}
            {/* <div id="incioAnimacionBalija" ref={bottomRef} ></div> */}
        </Fragment>

    );
}
 
export default Seccion_10

